.App {

}
 html{
  font-family: 'Helvetica', 'Arial', sans-serif;
  color: #f7f7f2;
  scroll-behavior: smooth;
  }
  a:link{
    color: #f7f7f2;
    text-decoration: none;
  }
  a:visited{
    color: #f7f7f2;
    text-decoration: none;
  }
  a:hover{
    color: #5c5c5c;
    text-decoration: none;
  }
  a:active{
    color: #5c5c5c;
    text-decoration: none;
  }
.Socials{
  padding-right: 3%;
  vertical-align:middle;
  filter: invert(100%) sepia(43%) saturate(394%) hue-rotate(341deg) brightness(108%) contrast(94%);
  height: 144px;
}
.SocialsMobile{
  filter: invert(100%) sepia(43%) saturate(394%) hue-rotate(341deg) brightness(108%) contrast(94%);
  height: 72px;
}

body{
  white-space: nowrap;
  width: 100%;
  height: 100vw;
  background-color: #0a0b1f;
}

.App-header {
  fill: #0a0b1f;
  background-color: #0a0b1f;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.App-headerMobile {
  fill: #0a0b1f;
  background-color: #0a0b1f;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  z-index: 10;
}

.App-header2 {
  background-color: #111229;
  height: 110vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-header2mobile {
  background-color: #111229;
  height: 110vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-header3 {
  /* overflow: auto;   */
  background-color: #111229;
  height: 110vh;
  /* width: 100%; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.App-header3mobile {
  /* overflow: auto;   */
  text-align: center;
  /* margin-top: 100px; */
  background-color: #111229;
  /* height: 110vh; */
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
}
.App-header4 {
  background-color: #0a0b1f;
  height: 100vh;
  width: 100%;
  display: flex;
  font-size: 44px;
  justify-content: center;
  align-items: center;
}
.App-header4Mobile {
  background-color: #0a0b1f;
  height: 100vh;
  width: 100%;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}
.SubHeader {
  display: flex;
  width: 80%;
}
.App-link {
  color: #61dafb;
}

.Avatar {
  z-index: 200;
  height: 30%;
  position: fixed;
  display: block;
  border-radius: 50%;
  border: solid 20px;
  border-color: #ebae14;
}

.Name {
  z-index: 200;
  position: fixed;
  font-size: 700%;
}

.SubName {
  z-index: 200;
  position: fixed;
  font-size: 200%;
}

.NavBar {
  z-index: 100;
  position: fixed;
  width: 100%;
  text-align: right;
  /* background-color: #0a0b1f; */
  padding-top: 20px;
  height: 10%;
  /* background-color: rgba(255, 255, 255, .15);   */
}
.NavBar text {
  font-size: 220%;
  padding-right: 60px;
}

.rightText {
  text-align: right;
}

button {
  background-color: transparent;
  border: none;
  height: 100%;
}

.ButtonText{
  color: #f7f7f2;
  font-size: 60px;
  padding-right: 60px;
}

.a{
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';
}

.ButtonTextMobile{
  z-index: 1;
  top: 1.5%;
  right: 5%;
  position: fixed;
}